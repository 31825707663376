/**
 * The Oops icon in 404 page
 * @returns SVG
 */
export default function Oops(): JSX.Element {
    return (
        <>
            <svg width="65" height="65" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3209_43144)">
                    <path
                        d="M24.1453 0.354082L0.354163 24.1452C-0.117915 24.6173 -0.117915 25.3827 0.354163 25.8548L24.1453 49.6459C24.6174 50.118 25.3828 50.118 25.8549 49.6459L49.6461 25.8548C50.1182 25.3827 50.1182 24.6173 49.6461 24.1452L25.8549 0.354082C25.3828 -0.117996 24.6174 -0.117994 24.1453 0.354082Z"
                        fill="#FFB61B"
                    />
                    <path
                        d="M24.9998 2.53396C25.0315 2.53366 25.063 2.53976 25.0923 2.55187C25.1217 2.56398 25.1483 2.58187 25.1706 2.60447L47.3949 24.8288C47.4402 24.8741 47.4656 24.9356 47.4656 24.9996C47.4656 25.0637 47.4402 25.1251 47.3949 25.1705L25.1706 47.3948C25.1253 47.4401 25.0638 47.4655 24.9998 47.4655C24.9357 47.4655 24.8742 47.4401 24.8289 47.3948L2.60462 25.1705C2.55934 25.1251 2.53391 25.0637 2.53391 24.9996C2.53391 24.9356 2.55934 24.8741 2.60462 24.8288L24.8289 2.60447C24.8512 2.58187 24.8778 2.56398 24.9072 2.55187C24.9365 2.53976 24.968 2.53366 24.9998 2.53396ZM24.9998 1.5669C24.841 1.56668 24.6838 1.59782 24.5371 1.65853C24.3905 1.71925 24.2573 1.80833 24.1451 1.92068L1.92082 24.145C1.6942 24.3717 1.56689 24.6791 1.56689 24.9996C1.56689 25.3202 1.6942 25.6276 1.92082 25.8543L24.1451 48.0786C24.3718 48.3052 24.6792 48.4325 24.9998 48.4325C25.3203 48.4325 25.6277 48.3052 25.8544 48.0786L48.0787 25.8543C48.3053 25.6276 48.4326 25.3202 48.4326 24.9996C48.4326 24.6791 48.3053 24.3717 48.0787 24.145L25.8544 1.92068C25.7423 1.80833 25.609 1.71925 25.4624 1.65853C25.3157 1.59782 25.1585 1.56668 24.9998 1.5669Z"
                        fill="black"
                    />
                    <path
                        d="M16.4006 25.405C16.4006 26.2914 16.1601 26.984 15.6789 27.4825C15.1978 27.9811 14.5574 28.2303 13.7577 28.23C12.9577 28.23 12.3191 27.9808 11.8417 27.4825C11.3644 26.9842 11.1254 26.2917 11.1249 25.405V23.2863C11.1249 22.4033 11.3638 21.7101 11.8417 21.2067C12.3196 20.7033 12.9565 20.4514 13.7525 20.4512C14.5487 20.4512 15.1892 20.703 15.6741 21.2067C16.159 21.7104 16.4007 22.4036 16.3994 23.2863L16.4006 25.405ZM14.9311 23.2694C14.9311 22.7278 14.8306 22.3191 14.6297 22.0432C14.4287 21.7673 14.1362 21.6292 13.7521 21.629C13.3644 21.629 13.0736 21.7661 12.8797 22.0404C12.6857 22.3146 12.5889 22.7246 12.5892 23.2702V25.4058C12.5892 25.9543 12.6879 26.3657 12.8853 26.64C13.0828 26.9142 13.3736 27.0514 13.7577 27.0514C14.1384 27.0514 14.4291 26.9142 14.6301 26.64C14.831 26.3657 14.9315 25.9543 14.9315 25.4058L14.9311 23.2694Z"
                        fill="black"
                    />
                    <path
                        d="M17.0381 24.9692C17.0381 24.2144 17.2381 23.5965 17.6381 23.1157C18.0381 22.6349 18.5981 22.3958 19.3183 22.3985C20.0385 22.3985 20.5994 22.6382 21.001 23.1177C21.4026 23.5972 21.6041 24.2151 21.6054 24.9713V25.6671C21.6054 26.4287 21.404 27.0465 21.001 27.5207C20.5981 27.9948 20.0389 28.2319 19.3236 28.2319C18.5999 28.2319 18.0382 27.9948 17.6385 27.5207C17.2388 27.0465 17.0388 26.4287 17.0385 25.6671L17.0381 24.9692ZM18.5076 25.6631C18.5076 26.0964 18.5682 26.4361 18.6893 26.6821C18.8105 26.9282 19.0218 27.0514 19.3232 27.0516C19.6136 27.0516 19.8204 26.9277 19.9437 26.6797C20.067 26.4318 20.1284 26.0929 20.1279 25.6631V24.966C20.1279 24.5432 20.0665 24.2061 19.9437 23.9546C19.8209 23.7032 19.6125 23.5775 19.3183 23.5775C19.0228 23.5775 18.8143 23.704 18.6926 23.957C18.5709 24.2101 18.5094 24.5459 18.508 24.9644L18.5076 25.6631Z"
                        fill="black"
                    />
                    <path
                        d="M22.1522 24.9693C22.1522 24.2144 22.3521 23.5966 22.7518 23.1157C23.1515 22.6349 23.7115 22.3951 24.4317 22.3965C25.1516 22.3965 25.7125 22.6362 26.1144 23.1157C26.5162 23.5952 26.7177 24.2131 26.7188 24.9693V25.6652C26.7188 26.4267 26.5173 27.0446 26.1144 27.5187C25.7114 27.9928 25.1519 28.23 24.4357 28.2303C23.712 28.2303 23.1503 27.9933 22.7506 27.5191C22.3509 27.045 22.151 26.4271 22.151 25.6656L22.1522 24.9693ZM23.6217 25.6632C23.6217 26.0965 23.6823 26.4361 23.8035 26.6822C23.9246 26.9283 24.1354 27.0514 24.4357 27.0517C24.7266 27.0517 24.9335 26.9277 25.0562 26.6798C25.179 26.4318 25.2408 26.093 25.2416 25.6632V24.9661C25.2416 24.5432 25.1801 24.2061 25.057 23.9547C24.934 23.7032 24.7255 23.5775 24.4317 23.5775C24.1375 23.5775 23.9289 23.704 23.8059 23.9571C23.6829 24.2101 23.6215 24.5459 23.6217 24.9645V25.6632Z"
                        fill="black"
                    />
                    <path
                        d="M31.8461 25.7429C31.8461 26.4908 31.6833 27.0924 31.3577 27.5477C31.0322 28.0031 30.577 28.2306 29.9922 28.2303C29.7807 28.2344 29.5714 28.1872 29.3821 28.0929C29.1972 27.9954 29.0379 27.8557 28.9171 27.6851V30.2817H27.4424V22.5017H28.8083L28.8599 23.0573C28.9811 22.8603 29.1455 22.6934 29.3406 22.5694C29.5338 22.4527 29.7561 22.3932 29.9817 22.3977C30.5727 22.3977 31.0314 22.6409 31.3577 23.1274C31.6841 23.6139 31.8469 24.2553 31.8461 25.0515V25.7429ZM30.3713 25.0495C30.3713 24.6054 30.309 24.2491 30.1844 23.9805C30.0597 23.7118 29.8642 23.5775 29.5977 23.5775C29.4538 23.5741 29.3123 23.6149 29.1923 23.6944C29.0732 23.7785 28.9783 23.8924 28.9171 24.0248V26.6774C28.9794 26.7954 29.074 26.8932 29.1899 26.9594C29.3172 27.0253 29.4593 27.0575 29.6025 27.0529C29.876 27.0529 30.0725 26.9367 30.192 26.7044C30.3116 26.472 30.3713 26.1513 30.3713 25.7421V25.0495Z"
                        fill="black"
                    />
                    <path
                        d="M35.0024 26.6519C35.0027 26.5737 34.9861 26.4963 34.9539 26.4251C34.9216 26.3538 34.8744 26.2903 34.8155 26.2389C34.6906 26.1228 34.4516 25.98 34.0986 25.8105C33.5345 25.5994 33.1113 25.3605 32.829 25.0937C32.5466 24.8269 32.4056 24.4808 32.4059 24.0553C32.4059 23.5986 32.5868 23.2082 32.9486 22.8839C33.3105 22.5597 33.789 22.3985 34.3843 22.4004C35.0078 22.4004 35.5048 22.5667 35.8752 22.8993C36.2457 23.2318 36.4239 23.6559 36.4099 24.1714L36.3946 24.2028H34.971C34.971 23.9672 34.9227 23.7802 34.826 23.6419C34.7292 23.5035 34.5803 23.4342 34.3791 23.434C34.3046 23.4326 34.2305 23.4462 34.1613 23.4739C34.0922 23.5016 34.0292 23.5428 33.9761 23.5951C33.9235 23.6467 33.8816 23.7083 33.853 23.7763C33.8244 23.8443 33.8097 23.9173 33.8097 23.991C33.8097 24.0648 33.8244 24.1378 33.853 24.2058C33.8816 24.2737 33.9235 24.3353 33.9761 24.3869C34.0871 24.4893 34.3276 24.6287 34.6978 24.8052C35.2866 25.0059 35.7229 25.2439 36.0066 25.5192C36.2902 25.7946 36.4322 26.1555 36.4325 26.6019C36.4325 27.0793 36.242 27.4704 35.8611 27.7753C35.4802 28.0802 34.98 28.2325 34.3606 28.2322C33.7132 28.2322 33.2007 28.0442 32.8233 27.6681C32.4459 27.292 32.2659 26.8661 32.2834 26.3904L32.2935 26.3594H33.6437C33.6504 26.657 33.7197 26.8719 33.8516 27.0041C33.9835 27.1362 34.1653 27.202 34.3968 27.2015C34.5943 27.2015 34.7448 27.153 34.8485 27.056C34.9522 26.9591 35.0035 26.8244 35.0024 26.6519Z"
                        fill="black"
                    />
                    <path
                        d="M38.6998 25.4937H37.2186V20.5601H38.6998V25.4937ZM38.6894 28.1213H37.2339V26.7815H38.6882L38.6894 28.1213Z"
                        fill="black"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3209_43144">
                        <rect width="50" height="50" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
