import Oops from '@components/svgs/svgOops';
import { getDefaultData } from '@data/default';
import { getHolidayInfo } from '@data/holiday';
import styles from '@styles/common-scss/_404.module.scss';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import Layout from '../components/layout';
import TopDeals from '../components/partials/homePage/topDeals';
import { CardDataArrayType } from '../lib/types/cardProps';
import { DefaultProps } from '../lib/types/defaultProps';
import { holidayProps } from '../lib/types/holidayProps';
import { intro } from '../lib/types/introProps';

type Props = {
    holidayMenuContent: holidayProps;
    defaultData: DefaultProps;
    introApiData: intro;
    latestReviewsApiData: CardDataArrayType;
    topSitesApiData: CardDataArrayType;
};

export default function Custom404(props: Props): JSX.Element {
    return (
        <Layout holiday={props.holidayMenuContent}>
            <Head>
                <title>The server returned a 404</title>
                <meta name="keywords" content={props.defaultData.metaKeywords} />
                <meta property="og:title" content="The server returned a 404" />
                <meta property="og:image" content="https://assets.rabbitsreviews.com/images/rr-logo/00-default/OGLogo.png" />
                <meta property="og:url" name="title" content="https://www.rabbitsreviews.com/Default.aspx" />
                <meta name="robots" content={props.defaultData.metaRobots} />
            </Head>
            <div className={styles.list404}>
                <div className={styles.messageWrapper}>
                    <div className={styles.message404}>
                        <div className={styles.oopsIcon}>
                            <Oops />
                        </div>
                        <div className={styles.secondCol}>
                            <h1> That page doesn&apos;t exist.</h1>
                            <p>Luckily, we&apos;ve always got more to explore!</p>
                        </div>
                    </div>
                </div>
                <div className={styles.cards404}>
                    <TopDeals cards={props.topSitesApiData} currentPage={0} holiday={props.holidayMenuContent} />
                </div>
                <div className={styles.button}>
                    <Link href={'/porn/deals'}>
                        <a className={`${styles.toSearch}`} title="Show more sites">
                            <span>SHOW MORE SITES</span>
                        </a>
                    </Link>
                </div>
            </div>
        </Layout>
    );
}
export const getStaticProps: GetStaticProps = async () => {
    const defaultData = await getDefaultData();

    // Build the intro from the API
    const introHeaderApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippets/default-page-intro-h1');
    const introBodyApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippets/default-page-intro-body');
    const introApiData: intro = {
        title: await introHeaderApiHit.json(),
        body: await introBodyApiHit.json(),
        homepageTab: { content: '' }
    };

    const topSitesApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v2/sites/topsite?limit=36');
    const topSitesApiData = await topSitesApiHit.json();

    const latestReviewsApiHit = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v2/sites/latestreview?limit=36');
    const latestReviewsApiData = await latestReviewsApiHit.json();

    // Refactored holiday menu here - this way it will be build server-side
    const holidayMenuContent = await getHolidayInfo();

    return {
        props: {
            holidayMenuContent,
            defaultData,
            introApiData,
            topSitesApiData,
            latestReviewsApiData
        },
        revalidate: 300 // Time in seconds before regenerating
    };
};
