import Card from '@components/partials/common/card/card';
import styles from '@styles/default-page-scss/list-cards/_list.module.scss';
import React from 'react';

import { CardDataArrayType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';

interface Types {
    cards: CardDataArrayType;
    currentPage: number;
    holiday: holidayProps;
}

export default function TopDeals({ cards, currentPage, holiday }: Types): JSX.Element {
    return (
        <div className={styles.allCards}>
            <div className={styles.cardList}>
                {cards.slice(0, currentPage * 12 + 12).map((card, tsindex) => (
                    <div className={styles.cardDiv} key={`cardDiv-${tsindex}`}>
                        <Card site={card} key={tsindex + 100} holiday={holiday} index={tsindex} pageTag={'default'} />
                    </div>
                ))}
            </div>
        </div>
    );
}
